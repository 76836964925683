@import 'variables';

h1, h2, h3, h4 {
  color: $usc_primary;
}

h2 {
  margin-top: 20px;
}

a {
  text-decoration: underline;
  color: $usc_primary;

  &:hover{
    color: $usc_darker;
  }
}

.petition-wrapper{
  button {
    .btn .btn-usc {
      background-color: $usc_primary;
      border: 100px;
    }
  }
}
