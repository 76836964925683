.petition-wrapper {
  .date-wrapper {
    text-align: right;
  }

  .date {
    font-size: 1.2em;
    font-weight: 300;
    color: #6d6d6d;
  }

  .expressions {
    margin-left: 15px;
    clear: both;
  }
}